<div class="header-container">
    <!--
            "d-flex" needed to avoid the <ul> to hold the entire width
    -->
    <nav class="d-flex">
        <ul>
            <!-- Visible on small screens (sm & xs) -->
            <ng-template [ngTemplateOutlet]="menuButton"
                         [ngTemplateOutletContext]="{ onClickFn: toggleSidebar.bind(this),
                             isActive: settings.app.sidebar.visible, class: 'd-block d-md-none' }"></ng-template>

            <!-- Visible on bigger screens (md & bigger) -->
            <ng-template [ngTemplateOutlet]="menuButton" *ngIf="settings.app.sidebar.offcanvas"
                         [ngTemplateOutletContext]="{ onClickFn: toggleSidebarOffcanvasVisible.bind(this),
                             isActive: settings.app.sidebar.offcanvasVisible, class: 'd-none d-md-block' }"></ng-template>
        </ul>

        <div id="titleLogoAndRightButtonsContainer">

            <!-- Contains everything, except for the buttons at the right -->
            <div class="col-10">
                <!--
                    No margin around the title, except for "sm" screens and bigger : they can have a small
                    horizontal margin
                -->
                <div id="header-title" class="m-0 mx-sm-2" [innerHTML]="pt.getTitle()"></div>

                <!--
                        My Sam Logo
                        Centered - It has a width and the "m-auto" class
                -->
                <div class="d-flex align-items-center m-auto justify-content-center">
                    <div style="width: 100px">
                        <img class="w-100 p-2 pt-3"
                             [src]="isLibertyDriver ? 'assets/img/liberty-driver/liberty-driver-white.png' : 'assets/img/mysam.png'"/>
                    </div>
                    <!-- Card display a message to alert  -->
                    <ng-container *ngIf="shouldDisplayAlertMessage()">
                        <mat-card appearance="outlined" class="w-50 primary-bg-color p-2">
                            <mat-card-content class="bold d-flex justify-content-around">
                            <span class="pt-2">
                                {{ 'header.ALERT-MESSAGE-AFTER-NONCE-TOKEN' |
                                mysamTranslate: { firstAndLastName: getPreviouslyMySamName(),
                                libertyDriverAccount: accessRights.currentUserSub.value.getDisplayName() } }}
                            </span>
                                <msl-accent-button (click)="logoutAndClearCache()">{{ 'header.LOGOUT' | mysamTranslate }}</msl-accent-button>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </div>
            </div>

            <div class="col-2">
                <div class="float-end d-flex align-items-center">
                    <!-- TODO Continue to dev this -->
                    <!--                    <li dropdown class="dropdown">-->
                    <!--                        <a dropdownToggle class="dropdown-toggle has-badge ripple" (click)="reportBug()">-->
                    <!--                            <img src="assets/img/bug_report.png" style="width: 24px" />-->
                    <!--                        </a>-->
                    <!--                    </li>-->

                    <!-- Notification only for MySam user -->
                    <ng-container *ngIf="accessRights.isUserBoundToMySam$ | async">
                        <mys-header-alerts-container></mys-header-alerts-container>
                    </ng-container>

                    <!-- Help icon only for liberty driver -->
                    <ng-container *ngIf="isLibertyDriver">
                        <button mat-icon-button (click)="onHelpIconClicked()">
                            <mat-icon>help</mat-icon>
                        </button>
                    </ng-container>

                    <div #openProfileOverlay style="height: auto">
                        <button mat-icon-button (click)="profileOverlay.toggle($event, openProfileOverlay)">
                            <mat-icon>person</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<p-overlayPanel #profileOverlay>
    <ng-template pTemplate>
        <div id="profile-overlay">
            <div class="d-flex justify-content-around">
                <a (click)="changeLanguage('fr')"
                   [title]="'header.SWITCH-TO-FRENCH' | mysamTranslate">
                    <img src="../../../assets/img/icons/flags/france.png"/>
                </a>
                <a (click)="changeLanguage('en')"
                   [title]="'header.SWITCH-TO-ENGLISH' | mysamTranslate">
                    <img src="../../../assets/img/icons/flags/uk.png"/>
                </a>
            </div>

            <ng-template [ngTemplateOutlet]="singleLinkRow" *ngIf="isLibertyDriver"
                         [ngTemplateOutletContext]="{ onClickFn: goToSubscriptionComponent.bind(this),
                             icon: 'clipboard-outline', messageKey: 'menu.MY-SUBSCRIPTION' }"></ng-template>

            <ng-template [ngTemplateOutlet]="singleLinkRow"
                         [ngTemplateOutletContext]="{ onClickFn: logout.bind(this), icon: 'log-out-outline',
                              messageKey: 'header.LOGOUT' }"></ng-template>
        </div>
    </ng-template>
</p-overlayPanel>

<!-- Reusable template, representing a link displayed as an "icon and text", and expanding on the whole row -->
<ng-template #singleLinkRow let-onClickFn="onClickFn" let-icon="icon" let-messageKey="messageKey">
    <div>
        <a (click)="onClickFn()" class="d-flex align-items-center">
            <ion-icon name="{{ icon }}" class="ion3x me-2"></ion-icon>
            {{ messageKey | mysamTranslate }}
        </a>
    </div>
</ng-template>

<!--
    I don't really know why, but the original Centric template used to provide two different menu buttons,
    but only one displayed at a time (one for small screens, one for big screens).
    We kept it that way, but we refactored it into a proper ng-template
-->
<ng-template #menuButton let-clazz="class" let-isActive=isActive let-onClickFn="onClickFn">
    <li [ngClass]="{'active': isActive}" [class]="clazz">
        <a (click)="onClickFn()" [ngClass]="settings.app.header.menulink" class="menu-link">
            <span><em></em></span>
        </a>
    </li>
</ng-template>

<!-- Displays a big banner if the current User is a PENDING_APPROVAL Driver -->
<mys-pending-approval-banner></mys-pending-approval-banner>
