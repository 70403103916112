import { LayoutComponent } from '../layout/layout.component';


import { BoundToMySamGuard } from '../../alfred/guards';
import { ErrorNotFoundComponent } from '../../alfred/errors/components/not-found/error-not-found.component';
import { LogoutComponent } from '../../../../msl-driver-registration/src/lib/login/components/logout/logout.component';
import {
    LibertyDriverPayingDriverGuard
} from '../../../../msl-driver-registration/src/lib/authentication/guards/liberty-driver-paying-driver-guard';
import {
    CurrentUserIsPayingGuard
} from '../../../../msl-driver-registration/src/lib/authentication/guards/current-paying-user-guard.service';
import { AdminOrSupervisorDriverGuard } from '../../alfred/guards/admin-or-supervisor-driver.guard';
import { BoundToCompanyGuard } from '../../alfred/guards/bound-to-company.guard';
import { MySamProtectedGuard, MySamPublicGuard } from 'msl-login';
import { FromToLocationsResolver } from "../../alfred/driver-activity/resolver/from-to-locations.resolver";
import { Routes } from "@angular/router";
import { LoginComponent } from "../../../../msl-driver-registration/src/lib/login/components/login/login.component";
import { LibertyDriverGuard, MY_INVOICES_PATH_WITHOUT_PREFIX_SLASH } from "../../alfred/guards/liberty-driver-guard";

export const routes: Routes = [

    /**
     * PublicGuard ensures that ONLY non-authenticated people can reach those routes
     */
    { path: 'login', component: LoginComponent, canActivate: [ MySamPublicGuard ] },
    {
        path: 'driver/signup',
        loadChildren: () => import('../../alfred/alfred-driver-signup/alfred-driver-signup.module')
            .then(m => m.AlfredDriverSignupModule),
        canActivate: [ MySamPublicGuard ]
    },
    /**
     * UPDATE 05/07/2023 - https://mysamcab.atlassian.net/browse/MYS-6300
     * Endpoint allowing Drivers to be auto-connected from their Driver app to Alfred
     */
    {
        path: 'nonce',
        loadChildren: () => import('../../alfred/nonce/nonce.module').then(m => m.NonceModule)
    },
    { path: 'logout', component: LogoutComponent, canActivate: [ MySamProtectedGuard ] },

    /**
     * Authenticated Routes
     */
    {
        path: '',
        component: LayoutComponent, canActivate: [ MySamProtectedGuard ],
        children: [

            // Custom routes defined by MySam
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            {
                path: 'home',
                loadChildren: () => import('../../alfred/liberty-driver/home/home.module').then(m => m.HomeModule),
                canActivate: [ LibertyDriverPayingDriverGuard ]
            },
            {
                path: '404',
                component: ErrorNotFoundComponent
            },
            {
                path: 'alfred-users',
                loadChildren: () => import('../../alfred/alfred-users/alfred-users.module').then(m => m.AlfredUsersModule),
                canActivate: [ CurrentUserIsPayingGuard ]
            },
            {
                path: 'clients',
                loadChildren: () => import('../../alfred/clients/clients.module').then(m => m.ClientsModule),
                canActivate: [ CurrentUserIsPayingGuard ]
            },

            /**
             * Accessible only from MySam users (for now, may be extended to Drivers in the future...)
             * Allows to list the Companies, and access their contacts & invoices
             */
            {
                path: 'companies',
                loadChildren: () => import('../../alfred/companies/companies.module').then(m => m.CompaniesModule),
                canActivate: [ BoundToMySamGuard ]
            },

            /**
             * Specific access for Company users (since they don't have access to the /companies path),
             * to access the invoices for their own Company
             */
            {
                path: 'company-invoices',
                loadChildren: () => import('../../alfred/companies/company/company-detail/company-detail.module').then(m => m.CompanyDetailModule),
                canActivate: [ BoundToCompanyGuard ] // CANNOT be accessed by MySam users (this path needs a Company bound to the AlfredUser)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../../alfred/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [ BoundToMySamGuard ]
            },
            {
                path: 'drivers',
                loadChildren: () => import('../../alfred/drivers/drivers.module').then(m => m.DriversModule),
                canActivate: [ AdminOrSupervisorDriverGuard ] // Can now be accessed by Supervisor Drivers as well
            },
            {
                path: 'driver-activity',
                loadChildren: () => import('../../alfred/driver-activity/driver-activity.module').then(m => m.DriverActivityModule),
                canActivate: [ BoundToMySamGuard ],
                resolve: { coordinates_address: FromToLocationsResolver }
            },
            {
                path: MY_INVOICES_PATH_WITHOUT_PREFIX_SLASH,
                loadChildren: () => import('../../alfred/liberty-driver/invoices/my-invoices.module').then(m => m.MyInvoicesModule),
                canActivate: [ LibertyDriverGuard ]
            },
            {
                path: 'my-subscription',
                loadChildren: () => import('../../alfred/liberty-driver/my-subscription/my-subscription.module').then(m => m.MySubscriptionModule),
                canActivate: [ LibertyDriverPayingDriverGuard ]
            },
            {
                path: 'parameters',
                loadChildren: () => import('../../alfred/parameters/parameters.module').then(m => m.ParametersModule),
                canActivate: [ AdminOrSupervisorDriverGuard ]
            },
            {
                path: 'payments',
                loadChildren: () => import('../../alfred/payment/trip-driver-payment/driver-transactions.module').then(m => m.DriverTransactionsModule),
                canActivate: [ BoundToMySamGuard ]
            },
            {
                path: 'tutorials',
                loadChildren: () => import('../../alfred/liberty-driver/liberty-tutorial/liberty-tutorial.module').then(m => m.LibertyTutorialModule),
            },
            {
                path: 'sponsor',
                loadChildren: () => import('../../alfred/liberty-driver/my-referral/my-referral.module').then(m => m.MyReferralModule),
            },
            {
                path: 'trip/create',
                loadChildren: () => import('../../alfred/trip-creation/trip-creation.module').then(m => m.TripCreationModule),
                canActivate: [ CurrentUserIsPayingGuard ]
            },
            {
                path: 'trips',
                loadChildren: () => import('../../alfred/trips/trips.module').then(m => m.TripsModule),
                canActivate: [ CurrentUserIsPayingGuard ]
            }
        ]
    },

    // Not found
    { path: '**', redirectTo: '404' }

];
