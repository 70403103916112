import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MysamLayoutModule } from './mysam/mysam.layout.module';
import { PipesModule } from '../../alfred/pipes/pipes.module';
import { MysamTranslateModule } from 'msl-translate';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatBadgeModule } from "@angular/material/badge";
import { AlertModule } from "../../alfred/alerts/alert.module";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MatCardModule } from '@angular/material/card';
import { RedirectButtonModule } from "../../alfred/utils/modules/redirect-button/redirect-button.module";
import { MslButtonModule } from "msl-components";

const components = [ LayoutComponent, SidebarComponent, HeaderComponent ];

@NgModule({
    imports: [
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MysamLayoutModule,
        MysamTranslateModule,
        OverlayPanelModule,
        AlertModule,
        PipesModule,
        RouterModule,
        SharedModule,
        RedirectButtonModule,
        MslButtonModule
    ],
    declarations: components,
    /**
     * See https://stackoverflow.com/a/63881579 - Option 2
     * Needed to avoid Angular complaining that 'ion-icon' is not a known element.
     */
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LayoutModule
{
}
