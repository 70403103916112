import { ConnectableUserTypeEnum } from "./connectable-user-type-enum";

export class TripEvent {
  /**
   * The Connectable Users of a TripEvent are serialized using only their IDs/first/last names, to lighten the JSON
   */
  tripId: number;
  mobileUserType: ConnectableUserTypeEnum;
  connectableUserType: ConnectableUserTypeEnum;
  connectableUser: { firstName: string, lastName: string, userId: string };
  mobileUser: { firstName: string, lastName: string, userId: string };
  translatedEvent: string;
  created: Date;
}
